.Lead-auditor-scroll-wrapper::-webkit-scrollbar {
  display: none;
}

.agents-wrapper::-webkit-scrollbar {
  display: none;
}
.view-wrapper {
  // .header-section {
    // .action-select {
      // .MuiPaper-root  {
        // background-color: red !important;
    // }
    // }
  // }
  .assignment-crads-wrapper {
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    flex-direction: row;
    .Lead-auditor-scroll-wrapper {
      overflow-y: scroll;
      overflow-x: hidden;
      // max-height: calc(100vh - 1000px);
      max-height: 91.9vh;
      direction: rtl;
      height: -moz-fit-content;
      height: fit-content;
      padding: 16px;
      width: 420px;
      border: 1px solid #e1e1e1;
      border-radius: 7px;
      margin: 0 2%;
      .Lead-auditor-wrapper {
        direction: ltr;

        // transform: rotate(180deg);

        .lead-auditor-search {
          margin-top: 16px;
          width: 293px;
          .mdi {
            color: #8e8e93;
          }
        }
        .Lead-auditor-title {
          width: 293px;
          background-color: #c2d3f3;
          display: flex;
          padding: 16px;
          height: 60px;
          border-radius: 8px;
          align-items: center;
          justify-content: space-between;
          span:nth-child(1) {
            font-weight: bold;
            font-size: 20px;
          }
          span:nth-child(2) {
            font-size: 16px;
          }
        }
        .selectedAudito {
          border: 1px solid var(--c-primary);
        }

        .lead-auditor-cards-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 9px;
          width: 293px;
          height: 185px;
          background: #f5faff;
          border-radius: 8px;
          margin: 16px 0;

          div:nth-child(1) {
            // width: 90%;

            .lead-auditor-image {
              margin: 0 16px 16px 0;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 0px;
              width: 40px;
              height: 40px;
              border-radius: 200px;
            }
            .title-wrapper {
              display: flex;
              flex-direction: column;
              span:nth-child(1) {
                width: 100%;
                height: 24px;
                font-weight: bold;
                font-size: 16px;
                color: #101828;
                white-space: nowrap;
                width: 85%;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              span:nth-child(2) {
                width: 100%;
                height: 20px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #475467;
              }
            }
          }
          .leads-row {
            display: flex;
            justify-content: space-around;
            width: 90%;
            .leads-numbers {
              display: flex;
              justify-content: space-between;
              width: 45%;
              display: grid;
              gap: 3px;
              grid-template-columns: repeat(3, minmax(30px, auto));
              grid-template-rows: 22px;
              .cold {
                background-color: #eff8ff;
                color: #175cd3;
              }
              .warm {
                background-color: #fffaeb;
                color: #b54708;
              }
              .hot {
                background-color: #fef3f2;
                color: #b42318;
              }
              .lead-number {
                width: 30px;
                height: 22px;
                border-radius: 61%;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: fit-content;
                padding: 14%;
              }
            }
            .edit-btn {
              border-radius: 30px;
              border: 1px solid var(--c-primary);
              width: 79px;
              height: 25px;
              font-size: 15px;
              font-weight: bold;
            }
          }
          .agent-row {
            width: 100%;
            justify-content: space-evenly;
            margin-top: 16px;
            .green-lead-cap {
              color: #36bc36 !important;
            }
            .red-lead-cap {
              color: red !important;
            }
            div {
              display: flex;
              flex-direction: column;
              span:nth-child(1) {
                font-weight: bold;
                font-size: 14px;
                color: #000000;
              }
              span:nth-child(2) {
                font-weight: bold;
                font-size: 14px;
                color: #0C111D;
                display: flex;
                justify-content: space-around;
              }
            }
          }
        }
      }
    }

    .agents-wrapper {
      height: fit-content;
      padding: 16px;
      // width: fit-content;
      width: 90%;
      border: 1px solid #e1e1e1;
      border-radius: 7px;
      flex-wrap: wrap;
      max-height: 91.9vh;
      overflow-y: scroll;
      .agents-search {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        justify-content: flex-start !important;
        .admin-agent-search {
          width: 15% !important;
        }
        .mdi {
          color: #8e8e93;
        }
      }

      .Lead-auditor-title {
        display: flex;
        padding: 16px;
        height: 60px;
        background-color: #fef0c7;
        border-radius: 8px;
        align-items: center;
        span:nth-child(1) {
          font-weight: bold;
          font-size: 20px;
        }
        span:nth-child(2) {
          font-size: 16px;
          margin: 0 2%;
        }
      }
      .agents-cards-wrapper {
        flex-wrap: wrap;
        display: flex;
        justify-content: space-evenly;
      }
      .leads-cards-wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 9px;
        width: 350px;
        // height: 185px;
        background: #fefaf5;
        border-radius: 8px;
        margin: 16px 4px;
        max-width: 347px;
        // .avatar {
        .agents-image {
          margin: 0 16px 16px 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0px;
          width: 40px;
          height: 40px;
          border-radius: 200px;
        }
        .title-wrapper {
          display: flex;
          flex-direction: column;
          span:nth-child(1) {
            width: 100%;
            height: 24px;
            font-weight: bold;
            font-size: 16px;
            color: #101828;
            white-space: nowrap;
            width: 82%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          span:nth-child(2) {
            width: 100%;
            height: 20px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #475467;
            white-space: nowrap;
            width: 82%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .leads-row {
          display: flex;
          justify-content: space-around;
          .leads-numbers {
            display: flex;
            justify-content: space-between;
            width: 45%;
            display: grid;
            gap: 3px;
            grid-template-columns: repeat(3, minmax(70px, auto));
            grid-template-rows: 22px;
            .cold {
              background-color: #eff8ff;
              color: #175cd3;
            }
            .warm {
              background-color: #fffaeb;
              color: #b54708;
            }
            .hot {
              background-color: #fef3f2;
              color: #b42318;
            }
            .lead-number {
              width: 30px;
              height: 22px;
              border-radius: 61%;
              display: flex;
              justify-content: center;
              align-items: center;
              min-width: fit-content;
              padding: 14%;
            }
          }
          .detail-btn {
            border-radius: 30px;
            border: 1px solid var(--c-primary);
            width: 79px;
            height: 25px;
            font-size: 13px;
            font-weight: bold;
          }
        }
        // }
        .agent-row {
          width: 100%;
          justify-content: space-between;
          margin-top: 16px;
          div {
            display: flex;
            flex-direction: column;
            // margin: 0 2%;
            span:nth-child(1) {
              font-weight: bold;
              font-size: 14px;
              color: #000000;
            }
            span:nth-child(2) {
              font-weight: bold;
              font-size: 14px;
              color: #0C111D;
              display: flex;
              justify-content: space-around;
              align-items: flex-start;
            }
          }
        }
      }
    }
  }
}
