.loadable-video-wrapper{

    video{
        border-radius: 0.5rem 0.5rem 0 0;
    }
    .hide-controls {
        pointer-events: none;
    }

    .hide-controls::-webkit-media-controls-panel {
        display: none !important;
        opacity: 1 !important;
    }

}
