.inquiry-management-container {
    background-color: #fcfcfd;

    .action-btn {
        border-radius: 7px;
        font-size: 12px;
        font-weight: 600;
        text-transform: capitalize;
    }

    .discard-button {
        border: 1px solid #d3d7e0;
        background-color: #ffffff;
    }

    .add-button {
        background-color: #806944;
        color: #ffffff;

        &:hover {
            color: black;
        }
    }

    .create-form {
        background-color: #ffffff;
        border: 1px solid #f0f1f4;
        box-shadow: 10px 10px 10px 10px #fefefe;
    }

    .form-subtitle {
        color: var(--colors-text-text-primary-900, #101828);

        /* Text lg/Semibold */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        /* 155.556% */
    }

    .hr {
        background-color: #d3d7e0;
        margin: 1rem 0;
        width: 100%;
    }

    .labeled-field {
        display: flex;
        flex-direction: row;
        width: 100%;

        .labeled-field__label {
            width: 25%;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            color: #344054;
            text-wrap: pretty;
        }

        .labeled-field__component {
            width: 75%;
        }
    }

    .inquiry-types {
        display: flex;
        gap: 10px;
        width: 100%;


        .inquiry-type-card__icon {
            display: flex;
            width: 32px;
            height: 32px;
            padding: 8px;
            justify-content: center;
            align-items: center;
            background-color: #FEF7C3;
            border-radius: 100px;
            margin-right: 16px;
        }

        .inquiry-type-card {
            padding: 1rem;
            display: flex;
            width: 248px;
            height: 112px;
            border: 2px solid #f0f1f4;
            border-radius: 12px;
            cursor: pointer;

            &.selected {
                border: 2px solid #7F6944;
            }

            &.disabled-card {
                opacity: 0.5;
                pointer-events: none;
            }
        }

        .inquiry-type-card__name>strong {
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }

        .inquiry-type-card__description>P {
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }

        /* Hide the default checkbox */
        input[type="checkbox"] {
            display: none;
        }

        /* Create a custom checkbox */
        .checkbox-container {
            display: inline-block;
            position: relative;
            padding-left: 20px;
            /* Adjusted padding to fit the smaller checkbox */
            cursor: pointer;
            user-select: none;
        }

        /* Style the checkmark */
        .checkbox-container .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 16px;
            width: 16px;
            background-color: transparent;
            /* Unchecked background color */
            border: 1px solid #ccc;
            /* Border to make it visible */
            border-radius: 4px;
            transition: background-color 0.3s;
        }

        /* Change background color when the checkbox is checked */
        .checkbox-container input:checked+.checkmark {
            background-color: #7F6944;
            /* Desired checked color */
        }

        /* Create the checkmark (hidden when not checked) */
        .checkbox-container .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }

        /* Show the checkmark when checked */
        .checkbox-container input:checked+.checkmark:after {
            display: block;
        }

        /* Style the checkmark (the tick) */
        .checkbox-container .checkmark:after {
            left: 5px;
            top: 2px;
            width: 4px;
            height: 8px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }

    }

    .flex {
        display: flex;
        flex-wrap: wrap;
    }

    .chips-container {
        gap: 5px
    }

    .selected-chip-container {
        display: flex;
        padding: 2px 6px;
        justify-content: center;
        align-items: center;
        gap: 3px;
        border-radius: var(--radius-sm, 6px);
        border: 1px solid var(--Colors-Border-border-primary, #D0D5DD);
        background: var(--Colors-Background-bg-primary, #FFF);
    }

    .selected-chip {
        color: #344054;
        font-size: 12px;
    }

    .label-style {
        color: var(--colors-text-text-secondary-700, #344054);

        /* Text md/Medium */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 150% */
    }
}