.contact-details-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-right: 35px;
  width: 673px;
  padding: 35px;
  overflow: auto;
  height: 100%;
  padding-top: 1rem;
  .contact-details-title-wrapper {
    display: flex;
    flex-direction: column;
    .contact-details-title {
      color: #3b72d9;
      font-size: 20px;
      font-weight: 400;
    }
    .divider-title {
      border-bottom: #3b72d9 solid 1px;
      width: 46%;
    }
  }
  .contact-details-section1 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .section1-title {
      color: #000;
      font-size: 17px;
      font-weight: 600;
    }
    .section1-stepper-wrapper {
      list-style-type: none;
      display: flex;
      flex-wrap: nowrap;
      padding: 1rem 0;
      flex-direction: column;
      position: relative;
      width: 40%;

      .breadcrumb-level-contact {
        margin: 3px;
        position: relative;
        padding: 4px 6px;
        &::before,
        &::after {
          content: '';
          position: absolute;
          left: 0;
          height: 50%;
          width: 100%;
          background: #fff;
          z-index: -1;
        }

        &::before {
          top: 0;
          transform: skew(20deg);
          border: #e0e0e0 solid 1px;
          border-bottom: none;
        }

        &::after {
          bottom: 0;
          transform: skew(-20deg);
          border: #e0e0e0 solid 1px;
          border-top: none;
        }
        .step-content-wrapper {
          display: flex;
          align-items: center;
          display: flex;
          min-height: 44px;
          padding: 8px 12px;
          gap: 8px;
          .step-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            background-color: #1976d2;
            border-radius: 50%;
            margin-bottom: 8px;

            svg {
              fill: #fff;
              width: 21px;
              height: 20px;
            }
          }
          .step-text-wrapper {
            display: flex;
            flex-direction: column;
            .step-title {
              font-weight: 600;
              font-size: 14px;
              margin-bottom: 4px;
              color: #000;
              text-align: center;
            }

            .step-time {
              font-size: 12px;
              color: #757575;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .contact-details-section2 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .section2-title {
      color: #3468c5;
      font-size: 18px;
      font-weight: 500;
    }
    .pulling-details-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .pulling-details {
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        gap: 15px 12px;
        flex-wrap: wrap;
        min-height: 140px;
        width: 50%;
        .pulling-wrapper {
          display: flex;
          width: 115px;
          flex-direction: column;
          gap: 4px;
          min-height: 70px;

          .pulling-type {
            color: #696969;
            font-size: 14px;
            font-weight: 400;
            white-space: nowrap;
          }
        }
      }
    }
    .documents-wrapper-contact {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      .documents-content-contact {
        display: flex;
        align-items: center;
        gap: 5rem;
        .description-icon {
          display: flex;
          align-items: center;
          gap: 8px;
        }
        .description-link {
          display: flex;
          gap: 4px;
          color: #2153b1;
          align-items: center;
        }
        .read-more-icon {
          display: flex;
          align-self: flex-end;
        }
      }
    }
  }
}
.action-section3 {
  position: absolute;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px -2px 6px #00000029;
  width: 100%;
  padding: 1rem;
  bottom: 65px;
  right: -5px;
  .MuiButton-root {
    padding: 0.5rem;
  }
  .approve-btn {
    padding: 3px 35px;
  }
  .reject-btn {
    padding: 3px 35px;
    border: #db504a solid 1px;
    color: #db504a;
  }
}

.pulling-value {
  color: #212121;
  font-size: 14px;
  font-weight: 500;
}
.pulling-status,
.label-status {
  display: flex;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  border: 1px solid;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}
.pending {
  color: #b54708;
  border-color: #fedf89;
  background: #fffaeb;
}
.reject,
.fill {
  background: #fef3f2;
  color: #b42318;
}
.approved,
.pass {
  color: #027a48;
  background: #ecfdf3;
}
.hasbeenupdated {
  color: #035388;
  background: #e0f7ff;
}
