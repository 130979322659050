.leads-pool-cards-component-wrapper {
  .cards-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 100%;
    margin: 20px auto;
    gap: 12px;
    height: 200px;
    flex-wrap: nowrap;
  }

  .card-wrapper-custom {
    padding: 0;
    margin: 0;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    width: 150px;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 4px;
    padding: 4px;
    gap: 15px;
  }

  .upper-text {
    color: #333333;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    width: 100%;
    line-height: 1.1;
  }

  .middle-text {
    color: #037aff;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    width: 100%;
  }

  .bottom-text {
    color: #8f8f8f;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    width: 100%;
    position: relative;
  }

}
