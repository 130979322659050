.title-section{
    .sub-title-properties-AML{
      color: #3B72D9; 
      padding: 8px 16px; 
      border-bottom: 2px solid #3B72D9;
      display: inline-block;
      line-height: 30px;
      font-size: 20px;
      font-weight: 400;
      width: 25%;
      margin-bottom: 10px;
    }
  }

  .history-text{
    font-size: medium;
    color: #3f51b5;
    margin-left: 4px;
  }
  
  .pewpew{
    max-width: fit-content !important;
  }