.notification-body {
  font-family: "Inter";
  position: relative;
  text-align: left;
  border-radius: 8px;
  display: flex;
  gap: 4px;
  font-size: 14px;
  font-weight: 400;
  padding: 24px;
  flex-direction: column;
  margin: 8px 24px;

  p {
    margin: 0;
  }

  span {
    font-size: 12px;
  }

  label {
    font-weight: 400;
    margin-right: 12px;
  }
}

p,
span,
label {
  font-family: "Inter";
}

.dot {
  position: absolute;
  width: 6px;
  height: 6px;
  right: 24px;
}
