.template-wrapper {
  margin: 20px auto;
  width: 85%;
  border: 1px solid #ccc;
  padding: 20px;
  background-color: #f9f9f9;

  .template-container {
    width: 92%;
    overflow: hidden;
    margin: auto;
    background-color: #f9f9f9;

    .i-unchecked {
      display: none !important;
    }

    .MuiFormControlLabel-label {
      margin-left: 8px;
      font-size: 14px;
      color: #333;
    }
  }

  .form_actions {
    display: flex;
    justify-content: center;
    margin-block: 2rem;

    button {
      width: 100px;
      border-radius: 8px;
      margin-inline: 8px;
      display: flex;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      font-weight: 600;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      &.solid {
        border: 1px solid #363f72;
        background: #363f72;
        color: #fff;
      }

      &.outlined {
        border: 1px solid #d0d5dd;
        background: #fff;
        color: #344054;
      }
      &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }
    }
  }
}
