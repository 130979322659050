$c-primary: #3b72d9;
$c-purple: #ae00ff;
$c-primary-dark: #133c89;
$c-secondary: #2153b1;
$c-secondary-light: #e8f0ff;
$c-success: #31c7aa;
$c-success-light: #6ae5d3;
$c-pending: #d1a30b;
$c-warning: #ff827c;
$c-warning-light: #ffe2de;
$c-danger: #db504a;
$c-info: #857bff;
$c-black: #000000;
$c-black-dark: #020311;
$c-black-light: #2d3e50;
$c-white: #ffffff;
$c-white-dark: #dfe9ea;
$c-blue: #0066cc;
$c-blue-light: #3e7192;
$c-blue-lighter: #5fb7ff;
$c-blue-lightest: #b6d2ff;
$c-red: #ff0000;
$c-green-dark: #25d366;
$c-green: #8cc63e;
$c-green-light: #dbf5ee;
// Start New Colors (Gray)
$c-gray-primary: #3a3a3c;
$c-gray-secondary: #c7c7cc;
$c-gray-darker: #3a3a3c;
$c-gray-777: #777777;
$c-gray-dark: #8e8e93;
$c-gray: #aeaeb2;
$c-gray: #aeaeb2;
$c-yellow: #fbff02;
$c-ornage: #dfb023;
$c-gray-light: #c7c7cc;
$c-gray-ef: #efefef;
$c-gray-lighter: #f2f2f7;
$c-gray-lightest: #fafafa;
// End New Colors

// $c-gray-lighter: #e6e6e6;
// $c-gray-primary: #121212;
// $c-gray-secondary: #999999;
// $c-gray-dark: #4d4d4d;
// $c-gray-light: #b3b3b3;
// $c-gray-lighter: #fafafa;
// $c-gray-lightest: #f2f2f2;
$c-lavender: #e1e2fe;
$c-error: #f44336;
$bg-progress-gradient: linear-gradient(90deg, $c-warning 0%, $c-primary 45%, $c-success 100%);
$bg-progress-gradient-reversed: linear-gradient(
  -90deg,
  $c-warning 0%,
  $c-primary 45%,
  $c-success 100%
);
$bg-gradient-primary: linear-gradient(191deg, $c-primary 91%, $c-primary-dark 10%);
$bg-gradient-primary-right: linear-gradient(to right, $c-primary 0%, $c-primary-dark 100%);
$bg-gradient-primary-left: linear-gradient(to left, $c-primary 0%, $c-primary-dark 100%);
$bg-gradient-primary-top: linear-gradient(to top, $c-primary 0%, $c-primary-dark 100%);
$bg-gradient-primary-bottom: linear-gradient(to bottom, $c-primary 0%, $c-primary-dark 100%);

//:::::::::NEW CRM UI COLOR VARIABLES ::::::::::::
$text-primary: #101828;
$text-tertiary: #475467;

:root-new-color {
  --colors-background-bg-active: #f9fafb;
  --colors-background-bg-active-dark: #8c734b;
  --colors-background-bg-brand-primary: #f4f1ed;
  --colors-background-bg-brand-primary-dark: #f4f1ed;
  --colors-background-bg-brand-primary-alt: #f4f1ed;
  --colors-background-bg-brand-primary-alt-dark: #f9fafb;
  --colors-background-bg-brand-secondary: #fef7c3;
  --colors-background-bg-brand-secondary-dark: #7f6944;
  --colors-background-bg-brand-section: #4d3f29;
  --colors-background-bg-brand-section-dark: #f9fafb;
  --colors-background-bg-brand-section-subtle: #635235;
  --colors-background-bg-brand-section-subtle-dark: #ffffff;
  --colors-background-bg-brand-solid: #7f6944;
  --colors-background-bg-brand-solid-dark: #7f6944;
  --colors-background-bg-brand-solid-hover: #635235;
  --colors-background-bg-brand-solid-hover-dark: #8c734b;
  --colors-background-bg-disabled: #f2f4f7;
  --colors-background-bg-disabled-dark: #1f242f;
  --colors-background-bg-disabled-subtle: #f9fafb;
  --colors-background-bg-disabled-subtle-dark: #161b26;
  --colors-background-bg-disabled-subtle: #f9fafb;
  --colors-background-bg-disabled-subtle-dark: #161b26;
  --colors-background-bg-error-primary: #fef3f2;
  --colors-background-bg-error-primary-dark: #f04438;
  --colors-background-bg-error-secondary: #fee4e2;
  --colors-background-bg-error-secondary-dark: #d92d20;
  --colors-background-bg-error-solid: #d92d20;
  --colors-background-bg-error-solid-dark: #d92d20;
  --colors-background-bg-overlay: #0c111d;
  --colors-background-bg-overlay-dark: #1f242f;
  --colors-background-bg-primary: #ffffff;
  --colors-background-bg-primary-dark: #0c111d;
  --colors-background-bg-secondary-solid: #475467;
  --colors-background-bg-secondary-alt: #f9fafb;
  --colors-background-bg-secondary-hover: #f2f4f7;
  --colors-background-bg-secondary-subtle: #fcfcfd;
  --colors-background-bg-primary-solid-dark: #f9fafb;
  --colors-background-bg-primary-alt-dark: #f9fafb;
  --colors-background-bg-primary-hover-dark: #1f242f;
  --colors-background-bg-quaternary-dark: #333741;
  --colors-background-bg-secondary-dark: #161b26;
  --colors-background-bg-success-primary: #ecfdf3;
  --colors-background-bg-success-secondary: #dcfae6;
  --colors-background-bg-success-solid: #079455;
  --colors-background-bg-tertiary: #f2f4f7;
  --colors-background-bg-warning-primary: #fffaeb;
  --colors-background-bg-warning-secondary: #fef0c7;
  --colors-background-bg-warning-solid: #dc6803;
  --colors-border-border-brand: #b2a186;
  --colors-border-border-brand-solid: #7f6944;
  --colors-border-border-brand-solid-alt: #7f6944;
  --colors-border-border-disabled: #d0d5dd;
  --colors-border-border-disabled-subtle: #eaecf0;
  --colors-border-border-error: #fda29b;
  --colors-border-border-error-solid: #d92d20;
  --colors-border-border-primary: #d0d5dd;
  --colors-border-border-secondary: #eaecf0;
  --colors-border-border-tertiary: #f2f4f7;
  --colors-background-bg-secondary-solid-dark: #61646c;
  --colors-background-bg-secondary-alt-dark: #ffffff;
  --colors-background-bg-secondary-hover-dark: #1f242f;
  --colors-background-bg-secondary-subtle-dark: #161b26;
  --colors-background-bg-success-primary-dark: #17b26a;
  --colors-background-bg-success-secondary-dark: #079455;
  --colors-background-bg-success-solid-dark: #079455;
  --colors-background-bg-tertiary-dark: #1f242f;
  --colors-background-bg-warning-primary-dark: #f79009;
  --colors-background-bg-warning-secondary-dark: #dc6803;
  --colors-background-bg-warning-solid-dark: #dc6803;
  --colors-border-border-brand-dark: #a38f6f;
  --colors-border-border-brand-solid-dark: #8c734b;
  --colors-border-border-brand-solid-alt-dark: #333741;
  --colors-border-border-disabled-dark: #333741;
  --colors-border-border-disabled-subtle-dark: #1f242f;
  --colors-border-border-error-dark: #f97066;
  --colors-border-border-error-solid-dark: #f04438;
  --colors-border-border-primary-dark: #333741;
  --colors-border-border-secondary-dark: #1f242f;
  --colors-border-border-tertiary-dark: #1f242f;
  --colors-foreground-fg-brand-primary-600: #7f6944;
  --colors-foreground-fg-brand-primary-alt: #7f6944;
  --colors-foreground-fg-brand-secondary-500: #8c734b;
  --colors-foreground-fg-disabled: #98a2b3;
  --colors-foreground-fg-disabled-subtle: #d0d5dd;
  --colors-foreground-fg-error-primary: #d92d20;
  --colors-foreground-fg-error-secondary: #f04438;
  --colors-foreground-fg-primary-900: #101828;
  --colors-foreground-fg-quarterary-500: #667085;
  --colors-foreground-fg-quarterary-hover: #475467;
  --colors-foreground-fg-quinary-400: #98a2b3;
  --colors-foreground-fg-quinary-hover: #667085;
  --colors-foreground-fg-secondary-700: #344054;
  --colors-foreground-fg-secondary-hover: #182230;
  --colors-foreground-fg-senary-300: #d0d5dd;
  --colors-foreground-fg-success-primary: #079455;
  --colors-foreground-fg-success-secondary: #17b26a;
  --colors-foreground-fg-tertiary-600: #475467;
  --colors-foreground-fg-tertiary-hover: #344054;
  --colors-foreground-fg-warning-primary: #dc6803;
  --colors-foreground-fg-warning-secondary: #f79009;
  --colors-foreground-fg-white: #ffffff;
  --colors-foreground-fg-brand-primary-600-dark: #8c734b;
  --colors-foreground-fg-brand-primary-alt-dark: #cecfd2;
  --colors-foreground-fg-brand-secondary-500-dark: #8c734b;
  --colors-foreground-fg-disabled-dark: #85888e;
  --colors-foreground-fg-disabled-subtle-dark: #61646c;
  --colors-foreground-fg-error-primary-dark: #f04438;
  --colors-foreground-fg-error-secondary-dark: #f97066;
  --colors-foreground-fg-primary-900-dark: #ffffff;
  --colors-foreground-fg-quarterary-500-dark: #94969c;
  --colors-foreground-fg-quarterary-hover-dark: #cecfd2;
  --colors-foreground-fg-quinary-400-dark: #85888e;
  --colors-foreground-fg-quinary-hover-dark: #94969c;
  --colors-foreground-fg-secondary-700-dark: #cecfd2;
  --colors-foreground-fg-secondary-hover-dark: #ececed;
  --colors-foreground-fg-senary-300-dark: #61646c;
  --colors-foreground-fg-success-primary-dark: #17b26a;
  --colors-foreground-fg-success-secondary-dark: #47cd89;
  --colors-foreground-fg-tertiary-600-dark: #94969c;
  --colors-foreground-fg-tertiary-hover-dark: #cecfd2;
  --colors-foreground-fg-warning-primary-dark: #f79009;
  --colors-foreground-fg-warning-secondary-dark: #fdb022;
  --colors-foreground-fg-white-dark: #ffffff;
  --component-colors-alpha-alpha-black-10: #ffffff1a;
  --component-colors-alpha-alpha-black-100: #ffffff;
  --component-colors-alpha-alpha-black-20: #ffffff33;
  --component-colors-alpha-alpha-black-30: #ffffff4d;
  --component-colors-alpha-alpha-black-40: #ffffff66;
  --component-colors-alpha-alpha-black-50: #ffffff80;
  --component-colors-alpha-alpha-black-60: #ffffff99;
  --component-colors-alpha-alpha-black-70: #ffffffb2;
  --component-colors-alpha-alpha-black-80: #ffffffcc;
  --component-colors-alpha-alpha-black-90: #ffffffe5;
  --component-colors-alpha-alpha-white-10: #0c111d1a;
  --component-colors-alpha-alpha-white-100: #0c111d;
  --component-colors-alpha-alpha-white-20: #0c111d33;
  --component-colors-alpha-alpha-white-30: #0c111d4d;
  --component-colors-alpha-alpha-white-40: #0c111d66;
  --component-colors-alpha-alpha-white-50: #0c111d80;
  --component-colors-alpha-alpha-white-60: #0c111d99;
  --component-colors-alpha-alpha-white-70: #0c111db2;
  --component-colors-alpha-alpha-white-80: #0c111dcc;
  --component-colors-alpha-alpha-white-90: #0c111de5;
  --component-colors-alpha-alpha-black-10-dark: #0000001a;
  --component-colors-alpha-alpha-black-100-dark: #000000;
  --component-colors-alpha-alpha-black-20-dark: #00000033;
  --component-colors-alpha-alpha-black-30-dark: #0000004d;
  --component-colors-alpha-alpha-black-40-dark: #00000066;
  --component-colors-alpha-alpha-black-50-dark: #00000080;
  --component-colors-alpha-alpha-black-60-dark: #00000099;
  --component-colors-alpha-alpha-black-70-dark: #000000b2;
  --component-colors-alpha-alpha-black-80-dark: #000000cc;
  --component-colors-alpha-alpha-black-90-dark: #000000e5;
  --component-colors-alpha-alpha-white-10-dark: #ffffff1a;
  --component-colors-alpha-alpha-white-100-dark: #ffffff;
  --component-colors-alpha-alpha-white-20-dark: #ffffff33;
  --component-colors-alpha-alpha-white-30-dark: #ffffff4d;
  --component-colors-alpha-alpha-white-40-dark: #ffffff66;
  --component-colors-alpha-alpha-white-50-dark: #ffffff80;
  --component-colors-alpha-alpha-white-60-dark: #ffffff99;
  --component-colors-alpha-alpha-white-70-dark: #ffffffb2;
  --component-colors-alpha-alpha-white-80-dark: #ffffffcc;
  --component-colors-alpha-alpha-white-90-dark: #ffffffe5;
  --component-colors-components-app-store-badges-app-store-badge-border: #a6a6a6;
  --component-colors-components-application-navigation-nav-item-button-icon-fg: #667085;
  --component-colors-components-application-navigation-nav-item-button-icon-fg-active: #344054;
  --component-colors-components-application-navigation-nav-item-icon-fg: #667085;
  --component-colors-components-application-navigation-nav-item-icon-fg-active: #667085;
  --component-colors-components-avatars-avatar-bg: #f2f4f7;
  --component-colors-components-avatars-avatar-contrast-border: #00000014;
  --component-colors-components-avatars-avatar-focus-border: #98a2b324;
  --component-colors-components-avatars-avatar-profile-photo-border: #ffffff;
  --component-colors-components-breadcrumbs-breadcrumb-bg-hover: #f9fafb;
  --component-colors-components-breadcrumbs-breadcrumb-brand-bg-hover: #f4f1ed;
  --component-colors-components-breadcrumbs-breadcrumb-brand-fg-hover: #635235;
  --component-colors-components-breadcrumbs-breadcrumb-brand-icon-fg-hover: #635235;
  --component-colors-components-breadcrumbs-breadcrumb-fg: #475467;
  --component-colors-components-breadcrumbs-breadcrumb-fg-hover: #344054;
  --component-colors-components-breadcrumbs-breadcrumb-icon-fg: #667085;
  --component-colors-components-breadcrumbs-breadcrumb-icon-fg-hover: #344054;
  --component-colors-components-buttons-primary-button-primary-bg: #7f6944;
  --component-colors-components-buttons-primary-button-primary-bg-hover: #635235;
  --component-colors-components-buttons-primary-button-primary-border: #7f6944;
  --component-colors-components-buttons-primary-button-primary-border-hover: #635235;
  --component-colors-components-buttons-primary-button-primary-fg: #ffffff;
  --component-colors-components-buttons-primary-button-primary-fg-hover: #ffffff;
  --component-colors-components-buttons-primary-error-button-primary-error-bg: #d92d20;
  --component-colors-components-buttons-primary-error-button-primary-error-bg-hover: #b42318;
  --component-colors-components-buttons-primary-error-button-primary-error-border: #d92d20;
  --component-colors-components-buttons-primary-error-button-primary-error-border-hover: #b42318;
  --component-colors-components-buttons-primary-error-button-primary-error-fg: #ffffff;
  --component-colors-components-buttons-primary-error-button-primary-error-fg-hover: #ffffff;
  --component-colors-components-buttons-secondary-button-secondary-bg: #ffffff;
  --component-colors-components-buttons-secondary-button-secondary-bg-hover: #f9fafb;
  --component-colors-components-buttons-secondary-button-secondary-border: #d0d5dd;
  --component-colors-components-buttons-secondary-button-secondary-border-hover: #d0d5dd;
  --component-colors-components-buttons-secondary-button-secondary-fg: #344054;
  --component-colors-components-app-store-badges-app-store-badge-border-dark: #ffffff;
  --component-colors-components-application-navigation-nav-item-button-icon-fg-dark: #94969c;
  --component-colors-components-application-navigation-nav-item-button-icon-fg-active-dark: #ececed;
  --component-colors-components-application-navigation-nav-item-icon-fg-dark: #94969c;
  --component-colors-components-application-navigation-nav-item-icon-fg-active-dark: #ececed;
  --component-colors-components-avatars-avatar-bg-dark: #1f242f;
  --component-colors-components-avatars-avatar-contrast-border-dark: #ffffff1f;
  --component-colors-components-avatars-avatar-focus-border-dark: #98a2b324;
  --component-colors-components-avatars-avatar-profile-photo-border-dark: #0c111d;
  --component-colors-components-breadcrumbs-breadcrumb-bg-hover-dark: #1f242f;
  --component-colors-components-breadcrumbs-breadcrumb-brand-bg-hover-dark: #1f242f;
  --component-colors-components-breadcrumbs-breadcrumb-brand-fg-hover-dark: #ffffff;
  --component-colors-components-breadcrumbs-breadcrumb-brand-icon-fg-hover-dark: #ffffff;
  --component-colors-components-breadcrumbs-breadcrumb-fg-dark: #cecfd2;
  --component-colors-components-breadcrumbs-breadcrumb-fg-hover-dark: #ffffff;
  --component-colors-components-breadcrumbs-breadcrumb-icon-fg-dark: #94969c;
  --component-colors-components-breadcrumbs-breadcrumb-icon-fg-hover-dark: #ffffff;
  --component-colors-components-buttons-primary-button-primary-bg-dark: #7f6944;
  --component-colors-components-buttons-primary-button-primary-bg-hover-dark: #635235;
  --component-colors-components-buttons-primary-button-primary-border-dark: #7f6944;
  --component-colors-components-buttons-primary-button-primary-border-hover-dark: #635235;
  --component-colors-components-buttons-primary-button-primary-fg-dark: #ffffff;
  --component-colors-components-buttons-primary-button-primary-fg-hover-dark: #ffffff;
  --component-colors-components-buttons-primary-error-button-primary-error-bg-dark: #d92d20;
  --component-colors-components-buttons-primary-error-button-primary-error-bg-hover-dark: #b42318;
  --component-colors-components-buttons-primary-error-button-primary-error-border-dark: #d92d20;
  --component-colors-components-buttons-primary-error-button-primary-error-border-hover-dark: #b42318;
  --component-colors-components-buttons-primary-error-button-primary-error-fg-dark: #ffffff;
  --component-colors-components-buttons-primary-error-button-primary-error-fg-hover-dark: #ffffff;
  --component-colors-components-buttons-secondary-button-secondary-bg-dark: #161b26;
  --component-colors-components-buttons-secondary-button-secondary-bg-hover-dark: #1f242f;
  --component-colors-components-buttons-secondary-button-secondary-border-dark: #333741;
  --component-colors-components-buttons-secondary-button-secondary-border-hover-dark: #333741;
  --component-colors-components-buttons-secondary-button-secondary-fg-dark: #cecfd2;
  --component-colors-components-buttons-secondary-button-secondary-fg-hover-dark: #f0f1f1;
  --component-colors-components-buttons-secondary-button-secondary-fg-hover: #182230;
  --component-colors-components-buttons-secondary-color-button-secondary-color-bg: #ffffff;
  --component-colors-components-buttons-secondary-color-button-secondary-color-bg-hover: #f4f1ed;
  --component-colors-components-buttons-secondary-color-button-secondary-color-border: #b2a186;
  --component-colors-components-buttons-secondary-color-button-secondary-color-border-hover: #b2a186;
  --component-colors-components-buttons-secondary-color-button-secondary-color-fg: #635235;
  --component-colors-components-buttons-secondary-color-button-secondary-color-fg-hover: #4d3f29;
  --component-colors-components-buttons-secondary-error-button-secondary-error-bg: #ffffff;
  --component-colors-components-buttons-secondary-error-button-secondary-error-bg-hover: #fef3f2;
  --component-colors-components-buttons-secondary-error-button-secondary-error-border: #fda29b;
  --component-colors-components-buttons-secondary-error-button-secondary-error-border-hover: #fda29b;
  --component-colors-components-buttons-secondary-error-button-secondary-error-fg: #b42318;
  --component-colors-components-buttons-secondary-error-button-secondary-error-fg-hover: #912018;
  --component-colors-components-buttons-tertiary-button-tertiary-bg-hover: #f9fafb;
  --component-colors-components-buttons-tertiary-button-tertiary-fg: #475467;
  --component-colors-components-buttons-tertiary-button-tertiary-fg-hover: #344054;
  --component-colors-components-buttons-tertiary-color-button-tertiary-color-bg-hover: #f4f1ed;
  --component-colors-components-buttons-tertiary-color-button-tertiary-color-fg: #635235;
  --component-colors-components-buttons-tertiary-color-button-tertiary-color-fg-hover: #4d3f29;
  --component-colors-components-buttons-tertiary-error-button-tertiary-error-bg-hover: #fef3f2;
  --component-colors-components-buttons-tertiary-error-button-tertiary-error-fg: #b42318;
  --component-colors-components-buttons-tertiary-error-button-tertiary-error-fg-hover: #912018;
  --component-colors-components-footers-footer-badge-bg: #ecfdf3;
  --component-colors-components-footers-footer-badge-border: #abefc6;
  --component-colors-components-footers-footer-badge-fg: #067647;
  --component-colors-components-footers-footer-button-fg: #cabfac;
  --component-colors-components-footers-footer-button-fg-hover: #ffffff;
  --component-colors-components-header-sections-header-abstract-100-bg: #fef7c3;
  --component-colors-components-header-sections-header-abstract-200-bg: #cabfac;
  --component-colors-components-header-sections-header-abstract-300-bg: #b2a186;
  --component-colors-components-header-sections-header-abstract-50-bg: #f4f1ed;
  --component-colors-components-icons-featured-icons-dark-featured-icon-dark-fg-brand: #ffffff;
  --component-colors-components-icons-featured-icons-dark-featured-icon-dark-fg-error: #ffffff;
  --component-colors-components-icons-featured-icons-dark-featured-icon-dark-fg-gray: #ffffff;
  --component-colors-components-icons-featured-icons-dark-featured-icon-dark-fg-success: #ffffff;
  --component-colors-components-icons-featured-icons-dark-featured-icon-dark-fg-warning: #ffffff;
  --component-colors-components-icons-featured-icons-light-featured-icon-light-fg-brand: #7f6944;
  --component-colors-components-icons-featured-icons-light-featured-icon-light-fg-error: #d92d20;
  --component-colors-components-icons-featured-icons-light-featured-icon-light-fg-gray: #667085;
  --component-colors-components-icons-featured-icons-light-featured-icon-light-fg-success: #079455;
  --component-colors-components-icons-featured-icons-light-featured-icon-light-fg-warning: #dc6803;
  --component-colors-components-icons-featured-icons-modern-featured-icon-modern-border: #eaecf0;
  --component-colors-components-icons-icons-icon-fg-brand: #7f6944;
  --component-colors-components-buttons-secondary-button-secondary-fg-hover-dark: #182230;
  --component-colors-components-buttons-secondary-color-button-secondary-color-bg-dark: #161b26;
  --component-colors-components-buttons-secondary-color-button-secondary-color-bg-hover-dark: #1f242f;
  --component-colors-components-buttons-secondary-color-button-secondary-color-border-dark: #333741;
  --component-colors-components-buttons-secondary-color-button-secondary-color-border-hover-dark: #333741;
  --component-colors-components-buttons-secondary-color-button-secondary-color-fg-dark: #cecfd2;
  --component-colors-components-buttons-secondary-color-button-secondary-color-fg-hover-dark: #f0f1f1;
  --component-colors-components-buttons-secondary-error-button-secondary-error-bg-dark: #55160c;
  --component-colors-components-buttons-secondary-error-button-secondary-error-bg-hover-dark: #7a271a;
  --component-colors-components-buttons-secondary-error-button-secondary-error-border-dark: #912018;
  --component-colors-components-buttons-secondary-error-button-secondary-error-border-hover-dark: #b42318;
  --component-colors-components-buttons-secondary-error-button-secondary-error-fg-dark: #fecdca;
  --component-colors-components-buttons-secondary-error-button-secondary-error-fg-hover-dark: #fee4e2;
  --component-colors-components-buttons-tertiary-button-tertiary-bg-hover-dark: #1f242f;
  --component-colors-components-buttons-tertiary-button-tertiary-fg-dark: #94969c;
  --component-colors-components-buttons-tertiary-button-tertiary-fg-hover-dark: #ececed;
  --component-colors-components-buttons-tertiary-color-button-tertiary-color-bg-hover-dark: #1f242f;
  --component-colors-components-buttons-tertiary-color-button-tertiary-color-fg-dark: #cecfd2;
  --component-colors-components-buttons-tertiary-color-button-tertiary-color-fg-hover-dark: #f0f1f1;
  --component-colors-components-buttons-tertiary-error-button-tertiary-error-bg-hover-dark: #7a271a;
  --component-colors-components-buttons-tertiary-error-button-tertiary-error-fg-dark: #fda29b;
  --component-colors-components-buttons-tertiary-error-button-tertiary-error-fg-hover-dark: #fecdca;
  --component-colors-components-footers-footer-badge-bg-dark: #ffffff1a;
  --component-colors-components-footers-footer-badge-border-dark: #ffffff4d;
  --component-colors-components-footers-footer-badge-fg-dark: #ffffff;
  --component-colors-components-footers-footer-button-fg-dark: #cecfd2;
  --component-colors-components-footers-footer-button-fg-hover-dark: #f0f1f1;
  --component-colors-components-header-sections-header-abstract-100-bg-dark: #1f242f;
  --component-colors-components-header-sections-header-abstract-200-bg-dark: #333741;
  --component-colors-components-header-sections-header-abstract-300-bg-dark: #61646c;
  --component-colors-components-header-sections-header-abstract-50-bg-dark: #161b26;
  --component-colors-components-icons-featured-icons-dark-featured-icon-dark-fg-brand: #cabfac;
  --component-colors-components-icons-featured-icons-dark-featured-icon-dark-fg-error: #fecdca;
  --component-colors-components-icons-featured-icons-dark-featured-icon-dark-fg-gray: #ececed;
  --component-colors-components-icons-featured-icons-dark-featured-icon-dark-fg-success: #abefc6;
  --component-colors-components-icons-featured-icons-dark-featured-icon-dark-fg-warning: #fedf89;
  --component-colors-components-icons-icons-icon-fg-brand-on-brand: #cabfac;
  --component-colors-components-icons-social-icons-social-icon-fg-angellist: #000000;
  --component-colors-components-icons-social-icons-social-icon-fg-apple: #000000;
  --component-colors-components-icons-social-icons-social-icon-fg-github: #000000;
  --component-colors-components-icons-social-icons-social-icon-fg-instagram: #000100;
  --component-colors-components-icons-social-icons-social-icon-fg-tumblr: #001935;
  --component-colors-components-icons-social-icons-social-icon-fg-x: #242e36;
  --component-colors-components-mockups-screen-mockup-border: #101828;
  --component-colors-components-sliders-slider-handle-bg: #ffffff;
  --component-colors-components-sliders-slider-handle-border: #1570ef;
  --component-colors-components-thumbnail-thumbnail-badge-brand-fg: #635235;
  --component-colors-components-thumbnail-thumbnail-badge-success-fg: #067647;
  --component-colors-components-toggles-toggle-button-fg-disabled: #f9fafb;
  --component-colors-components-tooltips-tooltip-supporting-text: #d0d5dd;
  --component-colors-components-wysiwyg-editor-wysiwyg-editor-icon-fg: #98a2b3;
  --component-colors-components-wysiwyg-editor-wysiwyg-editor-icon-fg-active: #667085;
  --component-colors-utility-blue-utility-blue-100: #d1e9ff;
  --component-colors-utility-blue-utility-blue-200: #b2ddff;
  --component-colors-utility-blue-utility-blue-300: #84caff;
  --component-colors-utility-blue-utility-blue-400: #53b1fd;
  --component-colors-utility-blue-utility-blue-50: #eff8ff;
  --component-colors-utility-blue-utility-blue-500: #2e90fa;
  --component-colors-utility-blue-utility-blue-600: #1570ef;
  --component-colors-utility-blue-utility-blue-700: #175cd3;
  --component-colors-utility-blue-dark-utility-blue-dark-100: #d1e0ff;
  --component-colors-utility-blue-dark-utility-blue-dark-200: #b2ccff;
  --component-colors-utility-blue-dark-utility-blue-dark-300: #84adff;
  --component-colors-utility-blue-dark-utility-blue-dark-400: #528bff;
  --component-colors-utility-blue-dark-utility-blue-dark-50: #eff4ff;
  --component-colors-utility-blue-dark-utility-blue-dark-500: #2970ff;
  --component-colors-utility-blue-dark-utility-blue-dark-600: #155eef;
  --component-colors-utility-blue-dark-utility-blue-dark-700: #004eeb;
  --component-colors-utility-blue-light-utility-blue-light-100: #e0f2fe;
  --component-colors-utility-blue-light-utility-blue-light-200: #b9e6fe;
  --component-colors-utility-blue-light-utility-blue-light-300: #7cd4fd;
  --component-colors-utility-blue-light-utility-blue-light-400: #36bffa;
  --component-colors-utility-blue-light-utility-blue-light-50: #f0f9ff;
  --component-colors-utility-blue-light-utility-blue-light-500: #0ba5ec;
  --component-colors-utility-blue-light-utility-blue-light-600: #0086c9;
  --component-colors-utility-blue-light-utility-blue-light-700: #026aa2;
  --component-colors-components-icons-icons-icon-fg-brand-on-brand-dark: #94969c;
  --component-colors-components-icons-social-icons-social-icon-fg-angellist-dark: #ffffff;
  --component-colors-components-icons-social-icons-social-icon-fg-apple-dark: #ffffff;
  --component-colors-components-icons-social-icons-social-icon-fg-github-dark: #ffffff;
  --component-colors-components-icons-social-icons-social-icon-fg-instagram-dark: #ffffff;
  --component-colors-components-icons-social-icons-social-icon-fg-tumblr-dark: #ffffff;
  --component-colors-components-icons-social-icons-social-icon-fg-x-dark: #ffffff;
  --component-colors-components-mockups-screen-mockup-border-dark: #333741;
  --component-colors-components-sliders-slider-handle-bg-dark: #1570ef;
  --component-colors-components-sliders-slider-handle-border-dark: #ffffff;
  --component-colors-components-thumbnail-thumbnail-badge-brand-fg-dark: #cecfd2;
  --component-colors-components-thumbnail-thumbnail-badge-success-fg-dark: #cecfd2;
  --component-colors-components-toggles-toggle-button-fg-disabled-dark: #61646c;
  --component-colors-components-tooltips-tooltip-supporting-text-dark: #cecfd2;
  --component-colors-components-wysiwyg-editor-wysiwyg-editor-icon-fg-dark: #94969c;
  --component-colors-components-wysiwyg-editor-wysiwyg-editor-icon-fg-active-dark: #ffffff;
  --component-colors-utility-blue-utility-blue-100-dark: #194185;
  --component-colors-utility-blue-utility-blue-200-dark: #1849a9;
  --component-colors-utility-blue-utility-blue-300-dark: #175cd3;
  --component-colors-utility-blue-utility-blue-400-dark: #1570ef;
  --component-colors-utility-blue-utility-blue-50-dark: #102a56;
  --component-colors-utility-blue-utility-blue-500-dark: #2e90fa;
  --component-colors-utility-blue-utility-blue-600-dark: #53b1fd;
  --component-colors-utility-blue-utility-blue-700-dark: #84caff;
  --component-colors-utility-blue-dark-utility-blue-dark-100-dark: #00359e;
  --component-colors-utility-blue-dark-utility-blue-dark-200-dark: #0040c1;
  --component-colors-utility-blue-dark-utility-blue-dark-300-dark: #004eeb;
  --component-colors-utility-blue-dark-utility-blue-dark-400-dark: #155eef;
  --component-colors-utility-blue-dark-utility-blue-dark-50-dark: #002266;
  --component-colors-utility-blue-dark-utility-blue-dark-500-dark: #2970ff;
  --component-colors-utility-blue-dark-utility-blue-dark-600-dark: #528bff;
  --component-colors-utility-blue-dark-utility-blue-dark-700-dark: #84adff;
  --component-colors-utility-blue-light-utility-blue-light-100-dark: #0b4a6f;
  --component-colors-utility-blue-light-utility-blue-light-200-dark: #065986;
  --component-colors-utility-blue-light-utility-blue-light-300-dark: #026aa2;
  --component-colors-utility-blue-light-utility-blue-light-400-dark: #0086c9;
  --component-colors-utility-blue-light-utility-blue-light-50-dark: #062c41;
  --component-colors-utility-blue-light-utility-blue-light-500-dark: #0ba5ec;
  --component-colors-utility-blue-light-utility-blue-light-600-dark: #36bffa;
  --component-colors-utility-blue-light-utility-blue-light-700-dark: #7cd4fd;
  --component-colors-utility-brand-utility-brand-100: #fef7c3;
  --component-colors-utility-brand-utility-brand-200: #cabfac;
  --component-colors-utility-brand-utility-brand-300: #b2a186;
  --component-colors-utility-brand-utility-brand-400: #a38f6f;
  --component-colors-utility-brand-utility-brand-50: #f4f1ed;
  --component-colors-utility-brand-utility-brand-500: #8c734b;
  --component-colors-utility-brand-utility-brand-600: #7f6944;
  --component-colors-utility-brand-utility-brand-700: #635235;
  --component-colors-utility-brand-utility-brand-800: #4d3f29;
  --component-colors-utility-brand-utility-brand-900: #3b3020;
  --component-colors-utility-brown-utility-brown-100: #dbd4c7;
  --component-colors-utility-brown-utility-brown-200: #cabfac;
  --component-colors-utility-brown-utility-brown-300: #b2a186;
  --component-colors-utility-brown-utility-brown-400: #a38f6f;
  --component-colors-utility-brown-utility-brown-50: #f4f1ed;
  --component-colors-utility-brown-utility-brown-500: #8c734b;
  --component-colors-utility-brown-utility-brown-600: #7f6944;
  --component-colors-utility-brown-utility-brown-700: #635235;
  --component-colors-utility-error-utility-error-100: #fee4e2;
  --component-colors-utility-error-utility-error-200: #fecdca;
  --component-colors-utility-error-utility-error-300: #fda29b;
  --component-colors-utility-error-utility-error-400: #f97066;
  --component-colors-utility-error-utility-error-50: #fef3f2;
  --component-colors-utility-error-utility-error-500: #f04438;
  --component-colors-utility-error-utility-error-600: #d92d20;
  --component-colors-utility-error-utility-error-700: #b42318;
  --component-colors-utility-fuchsia-utility-fuchsia-100: #fbe8ff;
  --component-colors-utility-fuchsia-utility-fuchsia-200: #f6d0fe;
  --component-colors-utility-fuchsia-utility-fuchsia-300: #eeaafd;
  --component-colors-utility-fuchsia-utility-fuchsia-400: #e478fa;
  --component-colors-utility-fuchsia-utility-fuchsia-50: #fdf4ff;
  --component-colors-utility-fuchsia-utility-fuchsia-500: #d444f1;
  --component-colors-utility-fuchsia-utility-fuchsia-600: #ba24d5;
  --component-colors-utility-fuchsia-utility-fuchsia-700: #9f1ab1;
  --component-colors-utility-gray-utility-gray-100: #f2f4f7;
  --component-colors-utility-gray-utility-gray-200: #eaecf0;
  --component-colors-utility-gray-utility-gray-300: #d0d5dd;
  --component-colors-utility-gray-utility-gray-400: #98a2b3;
  --component-colors-utility-gray-utility-gray-50: #f9fafb;
  --component-colors-utility-gray-utility-gray-500: #667085;
  --component-colors-utility-gray-utility-gray-600: #475467;
  --component-colors-utility-gray-utility-gray-700: #344054;
  --component-colors-utility-gray-utility-gray-800: #182230;
  --component-colors-utility-gray-utility-gray-900: #101828;
  --component-colors-utility-brand-utility-brand-100-dark: #3b3020;
  --component-colors-utility-brand-utility-brand-100-alt-dark: #f2f4f7;
  --component-colors-utility-brand-utility-brand-200-dark: #4d3f29;
  --component-colors-utility-brand-utility-brand-200-alt-dark: #eaecf0;
  --component-colors-utility-brand-utility-brand-300-dark: #635235;
  --component-colors-utility-brand-utility-brand-300-alt-dark: #d0d5dd;
  --component-colors-utility-brand-utility-brand-400-dark: #7f6944;
  --component-colors-utility-brand-utility-brand-400-alt-dark: #98a2b3;
  --component-colors-utility-brand-utility-brand-50-dark: #2e2519;
  --component-colors-utility-brand-utility-brand-500-dark: #8c734b;
  --component-colors-utility-brand-utility-brand-500-alt-dark: #667085;
  --component-colors-utility-brand-utility-brand-50-alt-dark: #f9fafb;
  --component-colors-utility-brand-utility-brand-600-dark: #a38f6f;
  --component-colors-utility-brand-utility-brand-600-alt-dark: #475467;
  --component-colors-utility-brand-utility-brand-700-dark: #b2a186;
  --component-colors-utility-brand-utility-brand-700-alt-dark: #344054;
  --component-colors-utility-brand-utility-brand-800-dark: #cabfac;
  --component-colors-utility-brand-utility-brand-800-alt-dark: #182230;
  --component-colors-utility-brand-utility-brand-900-dark: #fef7c3;
  --component-colors-utility-brand-utility-brand-900-alt-dark: #101828;
  --component-colors-utility-brown-utility-brown-100-dark: #3b3020;
  --component-colors-utility-brown-utility-brown-200-dark: #4d3f29;
  --component-colors-utility-brown-utility-brown-300-dark: #635235;
  --component-colors-utility-brown-utility-brown-400-dark: #7f6944;
  --component-colors-utility-brown-utility-brown-50-dark: #2e2519;
  --component-colors-utility-brown-utility-brown-500-dark: #8c734b;
  --component-colors-utility-brown-utility-brown-600-dark: #a38f6f;
  --component-colors-utility-brown-utility-brown-700-dark: #b2a186;
  --component-colors-utility-error-utility-error-100-dark: #7a271a;
  --component-colors-utility-error-utility-error-200-dark: #912018;
  --component-colors-utility-error-utility-error-300-dark: #b42318;
  --component-colors-utility-error-utility-error-400-dark: #d92d20;
  --component-colors-utility-error-utility-error-50-dark: #55160c;
  --component-colors-utility-error-utility-error-500-dark: #f04438;
  --component-colors-utility-error-utility-error-600-dark: #f97066;
  --component-colors-utility-error-utility-error-700-dark: #fda29b;
  --component-colors-utility-fuchsia-utility-fuchsia-100-dark: #6f1877;
  --component-colors-utility-fuchsia-utility-fuchsia-200-dark: #821890;
  --component-colors-utility-fuchsia-utility-fuchsia-300-dark: #9f1ab1;
  --component-colors-utility-fuchsia-utility-fuchsia-400-dark: #ba24d5;
  --component-colors-utility-fuchsia-utility-fuchsia-50-dark: #47104c;
  --component-colors-utility-fuchsia-utility-fuchsia-500-dark: #d444f1;
  --component-colors-utility-fuchsia-utility-fuchsia-600-dark: #e478fa;
  --component-colors-utility-fuchsia-utility-fuchsia-700-dark: #eeaafd;
  --component-colors-utility-gray-utility-gray-100-dark: #1f242f;
  --component-colors-utility-gray-utility-gray-200-dark: #333741;
  --component-colors-utility-gray-utility-gray-300-dark: #333741;
  --component-colors-utility-gray-utility-gray-400-dark: #61646c;
  --component-colors-utility-gray-utility-gray-50-dark: #161b26;
  --component-colors-utility-gray-utility-gray-500-dark: #85888e;
  --component-colors-utility-gray-utility-gray-600-dark: #94969c;
  --component-colors-utility-gray-utility-gray-700-dark: #cecfd2;
  --component-colors-utility-gray-utility-gray-800-dark: #ececed;
  --component-colors-utility-gray-utility-gray-900-dark: #f0f1f1;
  --component-colors-utility-gray-blue-utility-gray-blue-100: #eaecf5;
  --component-colors-utility-gray-blue-utility-gray-blue-200: #d5d9eb;
  --component-colors-utility-gray-blue-utility-gray-blue-300: #b3b8db;
  --component-colors-utility-gray-blue-utility-gray-blue-400: #717bbc;
  --component-colors-utility-gray-blue-utility-gray-blue-50: #f8f9fc;
  --component-colors-utility-gray-blue-utility-gray-blue-500: #4e5ba6;
  --component-colors-utility-gray-blue-utility-gray-blue-600: #3e4784;
  --component-colors-utility-gray-blue-utility-gray-blue-700: #363f72;
  --component-colors-utility-indigo-utility-indigo-100: #e0eaff;
  --component-colors-utility-indigo-utility-indigo-200: #c7d7fe;
  --component-colors-utility-indigo-utility-indigo-300: #a4bcfd;
  --component-colors-utility-indigo-utility-indigo-400: #8098f9;
  --component-colors-utility-indigo-utility-indigo-50: #eef4ff;
  --component-colors-utility-indigo-utility-indigo-500: #6172f3;
  --component-colors-utility-indigo-utility-indigo-600: #444ce7;
  --component-colors-utility-indigo-utility-indigo-700: #3538cd;
  --component-colors-utility-orange-utility-orange-100: #fdead7;
  --component-colors-utility-orange-utility-orange-200: #f9dbaf;
  --component-colors-utility-orange-utility-orange-300: #f7b27a;
  --component-colors-utility-orange-utility-orange-400: #f38744;
  --component-colors-utility-orange-utility-orange-50: #fef6ee;
  --component-colors-utility-orange-utility-orange-500: #ef6820;
  --component-colors-utility-orange-utility-orange-600: #e04f16;
  --component-colors-utility-orange-utility-orange-700: #b93815;
  --component-colors-utility-orange-dark-utility-orange-dark-100: #ffe6d5;
  --component-colors-utility-orange-dark-utility-orange-dark-200: #ffd6ae;
  --component-colors-utility-orange-dark-utility-orange-dark-300: #ff9c66;
  --component-colors-utility-orange-dark-utility-orange-dark-400: #ff692e;
  --component-colors-utility-orange-dark-utility-orange-dark-50: #fff4ed;
  --component-colors-utility-orange-dark-utility-orange-dark-500: #ff4405;
  --component-colors-utility-orange-dark-utility-orange-dark-600: #e62e05;
  --component-colors-utility-orange-dark-utility-orange-dark-700: #bc1b06;
  --component-colors-utility-pink-utility-pink-100: #fce7f6;
  --component-colors-utility-pink-utility-pink-200: #fcceee;
  --component-colors-utility-pink-utility-pink-300: #faa7e0;
  --component-colors-utility-pink-utility-pink-400: #f670c7;
  --component-colors-utility-pink-utility-pink-50: #fdf2fa;
  --component-colors-utility-pink-utility-pink-500: #ee46bc;
  --component-colors-utility-pink-utility-pink-600: #dd2590;
  --component-colors-utility-pink-utility-pink-700: #c11574;
  --component-colors-utility-purple-utility-purple-100: #ebe9fe;
  --component-colors-utility-purple-utility-purple-200: #d9d6fe;
  --component-colors-utility-purple-utility-purple-300: #bdb4fe;
  --component-colors-utility-purple-utility-purple-400: #9b8afb;
  --component-colors-utility-purple-utility-purple-50: #f4f3ff;
  --component-colors-utility-purple-utility-purple-500: #7a5af8;
  --component-colors-utility-purple-utility-purple-600: #6938ef;
  --component-colors-utility-purple-utility-purple-700: #5925dc;
  --component-colors-utility-success-utility-success-100: #dcfae6;
  --component-colors-utility-success-utility-success-200: #abefc6;
  --component-colors-utility-success-utility-success-300: #75e0a7;
  --component-colors-utility-success-utility-success-400: #47cd89;
  --component-colors-utility-success-utility-success-50: #ecfdf3;
  --component-colors-utility-success-utility-success-500: #17b26a;
  --component-colors-utility-success-utility-success-600: #079455;
  --component-colors-utility-success-utility-success-700: #067647;
  --component-colors-utility-warning-utility-warning-100: #fef0c7;
  --component-colors-utility-warning-utility-warning-200: #fedf89;
  --component-colors-utility-warning-utility-warning-300: #fec84b;
  --component-colors-utility-warning-utility-warning-400: #fdb022;
  --component-colors-utility-warning-utility-warning-50: #fffaeb;
  --component-colors-utility-warning-utility-warning-500: #f79009;
  --component-colors-utility-warning-utility-warning-600: #dc6803;
  --component-colors-utility-warning-utility-warning-700: #b54708;
  --component-colors-utility-gray-blue-utility-gray-blue-100-dark: #101323;
  --component-colors-utility-gray-blue-utility-gray-blue-200-dark: #293056;
  --component-colors-utility-gray-blue-utility-gray-blue-300-dark: #363f72;
  --component-colors-utility-gray-blue-utility-gray-blue-400-dark: #3e4784;
  --component-colors-utility-gray-blue-utility-gray-blue-50-dark: #101323;
  --component-colors-utility-gray-blue-utility-gray-blue-500-dark: #4e5ba6;
  --component-colors-utility-gray-blue-utility-gray-blue-600-dark: #717bbc;
  --component-colors-utility-gray-blue-utility-gray-blue-700-dark: #b3b8db;
  --component-colors-utility-indigo-utility-indigo-100-dark: #2d3282;
  --component-colors-utility-indigo-utility-indigo-200-dark: #2d31a6;
  --component-colors-utility-indigo-utility-indigo-300-dark: #3538cd;
  --component-colors-utility-indigo-utility-indigo-400-dark: #444ce7;
  --component-colors-utility-indigo-utility-indigo-50-dark: #1f235b;
  --component-colors-utility-indigo-utility-indigo-500-dark: #6172f3;
  --component-colors-utility-indigo-utility-indigo-600-dark: #8098f9;
  --component-colors-utility-indigo-utility-indigo-700-dark: #a4bcfd;
  --component-colors-utility-orange-utility-orange-100-dark: #772917;
  --component-colors-utility-orange-utility-orange-200-dark: #932f19;
  --component-colors-utility-orange-utility-orange-300-dark: #b93815;
  --component-colors-utility-orange-utility-orange-400-dark: #e04f16;
  --component-colors-utility-orange-utility-orange-50-dark: #511c10;
  --component-colors-utility-orange-utility-orange-500-dark: #ef6820;
  --component-colors-utility-orange-utility-orange-600-dark: #f38744;
  --component-colors-utility-orange-utility-orange-700-dark: #f7b27a;
  --component-colors-utility-orange-dark-utility-orange-dark-100-dark: #771a0d;
  --component-colors-utility-orange-dark-utility-orange-dark-200-dark: #97180c;
  --component-colors-utility-orange-dark-utility-orange-dark-300-dark: #bc1b06;
  --component-colors-utility-orange-dark-utility-orange-dark-400-dark: #e62e05;
  --component-colors-utility-orange-dark-utility-orange-dark-50-dark: #57130a;
  --component-colors-utility-orange-dark-utility-orange-dark-500-dark: #ff4405;
  --component-colors-utility-orange-dark-utility-orange-dark-600-dark: #ff692e;
  --component-colors-utility-orange-dark-utility-orange-dark-700-dark: #ff9c66;
  --component-colors-utility-pink-utility-pink-100-dark: #851651;
  --component-colors-utility-pink-utility-pink-200-dark: #9e165f;
  --component-colors-utility-pink-utility-pink-300-dark: #c11574;
  --component-colors-utility-pink-utility-pink-400-dark: #dd2590;
  --component-colors-utility-pink-utility-pink-50-dark: #4e0d30;
  --component-colors-utility-pink-utility-pink-500-dark: #ee46bc;
  --component-colors-utility-pink-utility-pink-600-dark: #f670c7;
  --component-colors-utility-pink-utility-pink-700-dark: #faa7e0;
  --component-colors-utility-purple-utility-purple-100-dark: #3e1c96;
  --component-colors-utility-purple-utility-purple-200-dark: #4a1fb8;
  --component-colors-utility-purple-utility-purple-300-dark: #5925dc;
  --component-colors-utility-purple-utility-purple-400-dark: #6938ef;
  --component-colors-utility-purple-utility-purple-50-dark: #27115f;
  --component-colors-utility-purple-utility-purple-500-dark: #7a5af8;
  --component-colors-utility-purple-utility-purple-600-dark: #9b8afb;
  --component-colors-utility-purple-utility-purple-700-dark: #bdb4fe;
  --component-colors-utility-success-utility-success-100-dark: #074d31;
  --component-colors-utility-success-utility-success-200-dark: #085d3a;
  --component-colors-utility-success-utility-success-300-dark: #067647;
  --component-colors-utility-success-utility-success-400-dark: #079455;
  --component-colors-utility-success-utility-success-50-dark: #053321;
  --component-colors-utility-success-utility-success-500-dark: #17b26a;
  --component-colors-utility-success-utility-success-600-dark: #47cd89;
  --component-colors-utility-success-utility-success-700-dark: #75e0a7;
  --component-colors-utility-warning-utility-warning-100-dark: #7a2e0e;
  --component-colors-utility-warning-utility-warning-200-dark: #93370d;
  --component-colors-utility-warning-utility-warning-300-dark: #b54708;
  --component-colors-utility-warning-utility-warning-400-dark: #dc6803;
  --component-colors-utility-warning-utility-warning-50-dark: #4e1d09;
  --component-colors-utility-warning-utility-warning-500-dark: #f79009;
  --component-colors-utility-warning-utility-warning-600-dark: #fdb022;
  --component-colors-utility-warning-utility-warning-700-dark: #fec84b;
}

:root {
  --c-primary-light : #3b72d9;
  --c-primary-dark : #3b72d9;
  --c-primary-blue: #3b72d9;  
}

body {
  --colors-foreground-fg-secondary-700: #344054;
  --colors-background-bg-primary_hover:  #F9FAFB;
  --colors-foreground-fg-brand-primary-600: #7f6944;
  --colors-foreground-fg-disabled: #98a2b3;
  --colors-background-bg-primary: #ffffff;
  --colors-background-bg-active: #f9fafb;
  --colors-text-secondary-hover: #182230;
  --colors-border-border-secondary: #eaecf0;
  --colors-text-disabled: #667085;
  --colors-text-secondary: #344054;
  --colors-foreground-fg-primary-900: #101828;
  --colors-foreground-fg-tertiary-600: #475467;
  --colors-background-bg-brand-section-subtle: #635235;
  --colors-background-secondaryColor_bg_hover: #F4F1ED;
  --component-colors-utility-gray-utility-gray-100: #f2f4f7;
  --colors-foreground-fg-brand-tertiary-600: #7F6944;
  --colors-border-border-disabled: #d0d5dd;
  --colors-background-brand-solid: #7F6944;
}

body.new-layout__dark-mode {
  --colors-foreground-fg-secondary-700: #cecfd2;
  --colors-background-bg-primary_hover:  #1F242F;
  --colors-foreground-fg-brand-primary-600: #8c734b;
  --colors-foreground-fg-disabled: #85888e;
  --colors-background-bg-primary: #0c111d;
  --colors-background-bg-active: #8c734b;
  --colors-text-secondary-hover: #ECECED;
  --colors-border-border-secondary: #1f242f;
  --colors-text-disabled: #85888E;
  --colors-text-secondary: #CECFD2;
  --colors-foreground-fg-primary-900: #ffffff;
  --colors-foreground-fg-tertiary-600: #94969C;
  --colors-background-bg-brand-section-subtle: #CECFD2;
  --colors-background-secondaryColor_bg_hover: #1f242f;
  --component-colors-utility-gray-utility-gray-100: #1f242f;
  --colors-foreground-fg-brand-tertiary-600: #94969C;
  --colors-border-border-disabled: #333741;
  --colors-background-brand-solid: #7F6944;
}
