.qa-details-wrapper {
  .qaInfo-wrapper {
    font-size: 20px !important;
  }
  .leads-card-detaild-wrapper .slider-data {
    height: calc(100vh - 430px);

    .cards-wrapper {
      border: 1px solid darkgrey;
      border-radius: 1.1rem;
      color: #000000;
      width: 100%;
      max-width: fit-content;
      max-width: -moz-fit-content;
      min-width: 100%;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      padding: 10px;
      margin: 10px;
      .texts {
        word-break: break-all;
      }
      .date {
        display: flex !important;
        align-items: flex-end !important;
      }
      .mdi:before {
        width: fit-content !important;
        width: -moz-fit-content;
        font-size: 20px !important;
      }
      .close-status {
        display: flex;
        flex-direction: row-reverse;
        font-size: 13.5px;
        font-weight: 700;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        opacity: 0.7;
        color: #fb2418;
      }
      .open-status {
        display: flex;
        flex-direction: row-reverse;
        font-size: 13.5px;
        font-weight: 700;
        line-height: 2;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        opacity: 0.7;
        color: var(--c-primary);
      }
    }
  }
}
.totalTransactionContainer {
  font-size: small;
}
.allDetails {
  display: grid;
  gap: 20px;
}
.leaseAndSale {
  display: flex;
  justify-content: space-around;
}
.last-activity {
  font-size: small;
}
.title {
  font-size: small;
  font-weight: bold;
  margin: 5px 0 0 10px;
}
.detailsLastActivity {
  cursor: pointer;
  opacity: 1;
  background-color: #f2f2f7;
  border-radius: 12px;
  padding: 10px;
  text-align: left;
}

.totalTransaction {
  width: 50%;
  font: 18px;
  margin: 5px 0 0 10px;
  text-align: left;
  letter-spacing: 0px;
  color: #3a3a3c;
  opacity: 1;
  font-weight: bold;
}

.mdi-circle-medium {
  color: var(--c-primary);
}

.last-activity-div {
  display: flex;
  .first-call-1 {
    width: 50%;
    color: #3a3a3c;
    font: normal normal bold 12px/18px Poppins;
  }
  .first-call-2 {
    text-align: center;
    width: 50%;
    color: #3a3a3c;
    font: normal normal bold 12px/18px Poppins;
  }
}

.last-activity-description {
  display: flex;
  font-size: small;
  .last-activity-name {
    display: grid;
    width: 50%;
    .agent-name {
      color: #a5a5a5;
    }
    .agent-name-value {
      font: normal medium;
      letter-spacing: 0px;
      color: #3a3a3c;
      opacity: 1;
    }
  }
  .last-activity-type {
    display: grid;
    width: 50%;
    .date {
      text-align: center;
      color: #a5a5a5;
    }
    .date-value {
      text-align: center;
      letter-spacing: 0px;
      color: #3a3a3c;
      opacity: 1;
    }
  }
}

.textareaQA {
  width: 0;
  height: 0;
  padding: 0;
  opacity: 0;
}
.cuostam-check {
  .MuiCheckbox-root {
    padding: 0;
  }
}
.copy-id {
  display: flex;
  gap: 0.7rem;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  height: 1.5rem;
}

#text-area {
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  opacity: 0 !important;
}

.id-right-side {
  display: flex;
  gap: 0.4rem;
  margin-left: 0.35rem;
}

.data-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 50rem;
  &.Favourite {
    .PrivateSwitchBase-root-15 {
      padding: 0px !important;
    }
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 35rem;
    .w-10 {
      width: 50px;
    }
  }
}
.data-item {
  margin: 0.4rem;
  width: 47%;
  padding: 7px;
  max-height: 95px;
  overflow: auto;
  border-radius: 3px;
  background-color: #f4f4f4;
  &.act {
    background-color: #37393a5c;
  }
  &.vara {
    .PrivateSwitchBase-root-7 {
      padding: 0;
    }
    justify-content: space-evenly;
    width: 100%;
    padding-top: 7px;
    padding-top: 7px;
    padding-right: 0px;
    padding-left: 0px;
    .heart {
      cursor: pointer;
      .mdi:before {
        line-height: 100%;
        font-size: 19px;
        color: #db4848;
      }
    }
    .table-actions-wrapper {
      padding: 0 0.5rem;
      max-height: 27px;
      position: relative;
    }
  }
}

.UnitDetails-AddFavourite {
  cursor: pointer;
  .folder-plus .mdi:before {
    color: #f60f33;
  }

  display: flex;
  justify-content: center;
  align-items: center;
}
.UnitDetails {
  margin: 0.4rem;
  padding: 11px;
  width: 97%;
  font-size: 16px;
  color: white;
  font-weight: 700;
  border-radius: 3px;
  background: rgb(0, 212, 255);
  background: linear-gradient(
    36deg,
    rgba(0, 212, 255, 1) 0%,
    rgb(10, 10, 120) 35%,
    rgba(9, 9, 121, 0.691841702501313) 100%
  );
}
.details-img-uniots {
  position: absolute;
  right: 5rem;
  top: 2.4rem;
  max-width: 11rem;
  background-color: #0573bebf;
  width: 11rem;
  max-height: 8rem;
  box-shadow: -11px -7px 9px 1px #8a828257;
  border-radius: 10px;
  object-fit: cover;
}
.rtl .details-img-uniots {
  right: unset;
  left: 5rem;
}
