.clear-search-criteria-filters-button-container{
   min-width: 120px;
   background-color: #db504a;
   color: white;
   border: 2px #db504a;
   border-radius: 30px;
   max-height: 30px;
}

.clear-search-criteria-filters-button {
    min-height: 100%;
    width: 100%;
    font-size: 13px;
    white-space: nowrap;
}