.table-capsule {
  padding: 4px 12px;
  border-radius: 9999px;
  border: 1px solid #eaecf0;
  background: #f9fafb;
}

.clear-filter-button {
  height: 20px;
  margin-top: 25px;
}
