.filter-components {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    >div {
        margin: .5rem 1rem;
    }
}

.filter-section{
    .section1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
.btn-assign-to-me-first-click{
    width: 50%;
}

.btn-assign-to-me-second-click{
    width: 50%;
    background-color: cadetblue;
}

