.popover-unit-model {
  width: 100%;
  position: absolute;
  border-radius: 0.5rem;
  max-height: 300px;
  overflow-y: auto;
  background-color: #fafafa;
  z-index: 1;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 0px 5px 1px #00000029;
  .unit-model-item {
    min-height: 35px;
    border-radius: 0.5rem;
    margin: 0;
    width: 100%;
    min-width: 250px;
    color: $c-black-dark !important;
  }
}
